.video-player-container {
    margin: 0 auto; /* Center the container horizontally */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow for depth */
    width: 1020px;
    height: 600px;
    background: black;
    padding: 5px 0px 5px 0px;
  }

.video-player-container-mobile {
    margin: 0 auto; /* Center the container horizontally */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow for depth */
    width: auto;
    height: auto;
    background: black;
  }

  .loading-placeholder {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #333;
  }
  .ant-select-selector {
    border-radius: 5px !important;
    background: linear-gradient(135deg, #8d8686 0%, #17574f 100%); 
  }
  .ant-select-selection-placeholder {
    color: #fff !important;
  }
.ant-select-dropdown {
    border-radius: 12px; /* Rounded corners for the dropdown */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Dropdown shadow */
    background: #fff; /* White dropdown background */
    padding: 10px; /* Padding around dropdown items */
}

.ant-select-item.ant-select-item-option {
    margin: 3px;
    border-radius: 10px;
    background: linear-gradient(135deg, #e2dfdf 0%, #286C64 100%); /* Gradient background */
}
.ant-select-item.ant-select-item-option:hover {
  background: linear-gradient(135deg, #eae7e7 0%, #3aac9f 100%); /* Gradient background */
}